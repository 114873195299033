var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "96%"
    }
  }, [_vm.show ? _c('QrcodeStream', {
    staticStyle: {
      "display": "flex",
      "flex-flow": "column",
      "width": "100%",
      "height": "100%"
    },
    on: {
      "decode": _vm.onDecode
    }
  }, [_c('div', {
    staticClass: "pa-2 primary--text",
    staticStyle: {
      "background-color": "#FFFFFFA0"
    }
  }, [_vm._v(_vm._s(_vm.$t('qrcode.veuillez-scanner-le-qr-code-de-la-borne')))])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }