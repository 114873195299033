















import { Component, Vue } from "vue-property-decorator";

import { QrcodeStream } from "vue-qrcode-reader";

@Component({
  components: {
    QrcodeStream
  },
})
export default class Activate extends Vue {
  show: boolean = true;
  num: number | null = null;

  onDecode (decodedString: string): void {
    //console.log(decodedString)
    var splitedString = decodedString.split("/")
    console.log(splitedString)
    if (splitedString && splitedString.length > 0) {
      console.log(splitedString[splitedString.length -1 ])
      const v = parseInt(splitedString[splitedString.length -1 ])
      console.log(v)
      this.num = v
      this.$router.push('/hmi/box/' + this.num)
    }


    this.show = false
  }
  // mounted(): void {

  // }
}
